import React, { useEffect, useState } from 'react';
import '../style/biller-file.css';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderActivity from '../components/HeaderActivity';
import Swal from 'sweetalert2';
import ErrorPage from '../components/ErrorPage';

const API_URL = process.env.REACT_APP_API_URL;

const BbpsBiller = () => {
    const [companies, setCompanies] = useState([]);
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [error_page, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [category, setCategory] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredTransactions, setFilteredTransactions] = useState([]); // Holds filtered data

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setTitle(params.get('title'));

        if (params.get('category')) {
            fetchCompanies(params.get('category'));
            setCategory(params.get('category'));
        } else {
            Swal.fire({
                title: 'Failed!',
                text: 'Technical issue occurs.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(-1);
                }
            });
        }
    }, [location, navigate]); // Add navigate to the dependency array

    const fetchCompanies = async (pass_category) => {
        setLoading(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}operator.php?key=${key}&category=${pass_category}`);
            const data = await response.json();
            if (data.status === 'Success') {
                setCompanies(data.data1);
                setFilteredTransactions(data.data1); 
                setError(false);
                setErrorMsg('');
            } else {
                setError(true);
                setErrorMsg(data.message);
            }
        } catch (error) {
            setError(true);
            setErrorMsg(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCardClick = (operator_id) => {
        navigate(`../operator-form?operator_id=${operator_id}`);
    };

    const handleSearchChange = (e) => {
        const input = e.target.value;
        setSearchQuery(input); // Set the search query

        const filteredData = companies.filter((item) =>
            item.biller_name.toLowerCase().includes(input.toLowerCase()) ||
            item.biller_category.toLowerCase().includes(input.toLowerCase())
        );
        setFilteredTransactions(filteredData);
    };

    return (
        <div>
            {loading ? (
                <div className="loading_center">
                    <div className="loading"></div>
                </div>
            ) : error_page ? (
                <ErrorPage message={error_msg} callback={() => fetchCompanies(category)} />
            ) : (
                <div>
                    <HeaderActivity title={title} />
                    <div className='main-container'>
                        <div style={{ padding: '6px 10px 0px' }}>
                            <input
                                type="text"
                                placeholder='Search here...'
                                className='form-control'
                                autoComplete='off'
                                required
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className='table_div'>
                            {filteredTransactions.length === 0 ? (
                                <center>No records found</center>
                            ) : (
                                filteredTransactions.map(company => (
                                    <div className='margin_card' key={company.operator_id} onClick={() => handleCardClick(company.operator_id)}>
                                        <div>
                                            <img src={company.operator_logo} alt='biller' className="company-logo" />
                                        </div>
                                        <div className='biller_info'>
                                            <div className="biller_name">{company.biller_name}</div>
                                            <div className="biller_mode">{company.biller_category}</div>
                                        </div>
                                        <div className="margin">{parseFloat(company.commission).toFixed(2) + " " + company.commission_type}</div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BbpsBiller;
