import React from 'react'
import HeaderActivity from '../components/HeaderActivity'
import { Link } from 'react-router-dom'

import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import GasMeterOutlinedIcon from '@mui/icons-material/GasMeterOutlined';
import ConnectedTvOutlinedIcon from '@mui/icons-material/ConnectedTvOutlined';
import ElectricMeterOutlinedIcon from '@mui/icons-material/ElectricMeterOutlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import RouterOutlinedIcon from '@mui/icons-material/RouterOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import RentalIcon from '../assets/rental.svg';
import LandlineIcon from '../assets/landline.svg';

const Services = () => {
    return (
        <div>
            <HeaderActivity title='Service' />
            <div className='page-container'>
                <div className='service-card-item'>
                    <div className='service-card-item-header'>
                        <span className='card-service-header-bar'></span>
                        <p>Mobile Recharge & Others</p>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../mobile-prepaid-recharge?title=Mobile Recharge' className='service-item-card'>
                            <div className='service-item-content'>
                                <PhoneAndroidOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Mobile Prepaid</p>
                        </Link>
                        <Link to='../operator?category=DTH&title=DTH Recharge' className='service-item-card'>
                            <div className='service-item-content'>
                                <LiveTvOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>DTH</p>
                        </Link>
                        <Link to='../operator?category=Prepaid Meter&title=Prepaid Meter' className='service-item-card'>
                            <div className='service-item-content'>
                                <ElectricMeterOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Prepaid Meter</p>
                        </Link>
                        <Link to='../operator?category=Cable Tv&title=Cable Tv' className='service-item-card'>
                            <div className='service-item-content'>
                                <ConnectedTvOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Cable Tv</p>
                        </Link>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../operator?category=Fastag&title=Fastag' className='service-item-card'>
                            <div className='service-item-content'>
                                <DirectionsCarOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Fastag</p>
                        </Link>
                        <Link to='../operator?category=NCMC&title=NCMC' className='service-item-card'>
                            <div className='service-item-content'>
                                <img src={RentalIcon} alt='rental' style={{ height: '31px', width: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>NCMC</p>
                        </Link>
                        <Link to='../operator?category=NPS&title=NPS' className='service-item-card'>
                            <div className='service-item-content'>
                                <img src={RentalIcon} alt='rental' style={{ height: '31px', width: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>NPS</p>
                        </Link>
                        <Link className='service-item-card'>

                        </Link>
                    </div>
                </div>
                <div className='service-card-item'>
                    <div className='service-card-item-header'>
                        <span className='card-service-header-bar'></span>
                        <p>Utilities</p>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../operator?category=Mobile Postpaid&title=Mobile Postpaid' className='service-item-card'>
                            <div className='service-item-content'>
                                <PhoneIphoneOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Mobile Postpaid</p>
                        </Link>
                        <Link to='../operator?category=Gas&title=Gas' className='service-item-card'>
                            <div className='service-item-content'>
                                <LocalGasStationOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Gas</p>
                        </Link>
                        <Link to='../operator?category=Water&title=Water' className='service-item-card'>
                            <div className='service-item-content'>
                                <WaterDropOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Water</p>
                        </Link>
                        <Link to='../operator?category=Electricity&title=Electricity' className='service-item-card'>
                            <div className='service-item-content'>
                                <LightbulbOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Electricity</p>
                        </Link>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../operator?category=Broadband Postpaid&title=Broadband Postpaid' className='service-item-card'>
                            <div className='service-item-content'>
                                <RouterOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Broadband Postpaid</p>
                        </Link>
                        <Link to='../operator?category=LPG Gas&title=LPG Gas' className='service-item-card'>
                            <div className='service-item-content'>
                                <GasMeterOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>LPG Gas</p>
                        </Link>
                        <Link to='../operator?category=Landline Postpaid&title=Landline Postpaid' className='service-item-card'>
                            <div className='service-item-content'>
                                <img src={LandlineIcon} alt='landline' style={{ height: '31px', width: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Landline Postpaid</p>
                        </Link>
                        <Link className='service-item-card'>

                        </Link>
                    </div>
                </div>
                <div className='service-card-item'>
                    <div className='service-card-item-header'>
                        <span className='card-service-header-bar'></span>
                        <p>Finance & Tax</p>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../operator?category=Insurance&title=Insurance' className='service-item-card'>
                            <div className='service-item-content'>
                                <ShieldOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Insurance</p>
                        </Link>
                        <Link to='../operator?category=Municipal Taxes&title=Municipal Taxes' className='service-item-card'>
                            <div className='service-item-content'>
                                <BusinessOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Municipal Taxes</p>
                        </Link>
                        <Link to='../operator?category=Municipal Services&title=Municipal Services' className='service-item-card'>
                            <div className='service-item-content'>
                                <CorporateFareOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Municiapl Services</p>
                        </Link>
                        <Link to='../operator?category=Recurring Deposit&title=Recurring Deposit' className='service-item-card'>
                            <div className='service-item-content'>
                                <CalendarMonthOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Recurring Deposit</p>
                        </Link>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../operator?category=Loan Repayment&title=Loan Repayment' className='service-item-card'>
                            <div className='service-item-content'>
                                <PaymentsOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Loan Repayment</p>
                        </Link>
                        <Link to='../operator?category=Rental&title=Rental' className='service-item-card'>
                            <div className='service-item-content'>
                                <img src={RentalIcon} alt='rental' style={{ height: '31px', width: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Rental</p>
                        </Link>
                        <Link to='../operator?category=Credit Card&title=Credit Card' className='service-item-card'>
                            <div className='service-item-content'>
                                <CreditCardOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Credit Card</p>
                        </Link>
                        <Link to='../bbps-biller?category=Municipal Services&title=Municipal Service' className='service-item-card'>

                        </Link>
                    </div>
                </div>
                <div className='service-card-item'>
                    <div className='service-card-item-header'>
                        <span className='card-service-header-bar'></span>
                        <p>More Services</p>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../operator?category=Clubs & Associations&title=Clubs & Associations' className='service-item-card'>
                            <div className='service-item-content'>
                                <GroupsOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Clubs & Associations</p>
                        </Link>
                        <Link to='../operator?category=Hospital&title=Hospital' className='service-item-card'>
                            <div className='service-item-content'>
                                <LocalHospitalOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Hospital</p>
                        </Link>
                        <Link  to='../operator?category=Education Fees&title=Education Fees' className='service-item-card'>
                            <div className='service-item-content'>
                                <LibraryBooksOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Education Fees</p>
                        </Link>
                        <Link to='../operator?category=Health Insurance&title=Health Insurance' className='service-item-card'>
                            <div className='service-item-content'>
                                <HealthAndSafetyOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Health Insurance</p>
                        </Link>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../operator?category=Hospital & Pathology&title=Hospital & Pathology' className='service-item-card'>
                            <div className='service-item-content'>
                                <BiotechOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Hospital & Pathology</p>
                        </Link>
                        <Link to='../operator?category=Housing Society&title=Housing Society' className='service-item-card'>
                            <div className='service-item-content'>
                                <Diversity2OutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Housing Society</p>
                        </Link>
                        <Link to='../operator?category=Life Insurance&title=Life Insurance'  className='service-item-card'>
                            <div className='service-item-content'>
                                <Diversity1OutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Life Insurance</p>
                        </Link>
                        <Link to='../operator?category=Donation&title=Donation' className='service-item-card'>
                            <div className='service-item-content'>
                                <Inventory2OutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Donation</p>
                        </Link>
                    </div>
                    <div className='service-item-row'>
                        <Link to='../operator?category=Subscription&title=Subscription' className='service-item-card'>
                            <div className='service-item-content'>
                                <SubscriptionsOutlinedIcon style={{ fontSize: '31px' }} />
                            </div>
                            <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Subscription</p>
                        </Link>
                        <Link to='../bbps-biller?category=Subscription&title=Subscription' className='service-item-card'>

                        </Link>
                        <Link to='../bbps-biller?category=Housing Society&title=Housing Society' className='service-item-card'>

                        </Link>
                        <Link to='../bbps-biller?category=Hospital and Pathology&title=Hospital and Pathology' className='service-item-card'>

                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services