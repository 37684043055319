import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function BackPageRedirect() {
    const navigate = useNavigate();

    useEffect(() => {
        // Navigate two pages back
        navigate(-2);
    }, [navigate]);

    return (
        <div>
            <div className="loading_center">
                <div className="loading"></div>
            </div>
        </div>
    );
}

export default BackPageRedirect;
