import React, { useEffect, useState } from 'react';
import HeaderActivity from '../components/HeaderActivity';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import ErrorPage from '../components/ErrorPage';
import TransactionProcessing from '../components/TransactionProcessing';
import TransactionSucess from '../components/TransactionSucess';
import TransactionFailed from '../components/TransactionFailed';
import TransactionPending from '../components/TransactionPending';

const API_URL = process.env.REACT_APP_API_URL;

function RechargePlan() {
    const location = useLocation();
    const navigate = useNavigate();
    const [number, setNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [circle_id, setCircleId] = useState('');
    const [operator_id, setOperatorId] = useState('');
    const [plansData, setPlansData] = useState({});
    const [activeTab, setActiveTab] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [filteredPlans, setFilteredPlans] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [transactionProcessing, setTransactionProcessing] = useState(false);
    const [transactionPending, setTransactionPending] = useState(false);
    const [transactionSuccess, setTransactionSuccess] = useState(false);
    const [transactionFailed, setTransactionFailed] = useState(false);
    const [order_id, setOrderId] = useState('');
    const [txn_id, setTxnId] = useState('');
    const [txn_msg, setTxnMsg] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const number = queryParams.get('number');

        if (number) {
            setNumber(number);
            fetchOperatorState(number);
        } else {
            Swal.fire({
                title: 'Failed!',
                text: 'Technical issue occurred.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(() => {
                navigate(-1);
            });
        }
    }, [location, navigate]);

    useEffect(() => {
        if (operator_id !== '' && circle_id !== '') {
            fetchPlan(operator_id, circle_id);
        }
    }, [operator_id, circle_id]);

    const fetchOperatorState = async (number) => {
        setLoading(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}fetch_op_state.php?key=${key}&number=${number}`);
            if (!response.ok) throw new Error('Failed to fetch form inputs');
            const data = await response.json();

            if (data.status === 'Success') {
                setCircleId(data.data3)
                setOperatorId(data.data4)
            } else {
                setErrorPage(true);
                setErrorMsg(data.message);
            }
        } catch (error) {
            setErrorPage(true);
            setErrorMsg(error.message);
        }
    };

    const fetchPlan = async (operator_id, circle_id) => {
        setLoading(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}plans.php?key=${key}&operator_id=${operator_id}&circle_code=${circle_id}`);
            if (!response.ok) throw new Error('Failed to fetch form inputs');
            const data = await response.json();

            if (data.status === 'Success') {
                setPlansData(data.data1.records);
                setActiveTab(Object.keys(data.data1.records)[0]);
            } else {
                //setErrorPage(true);
                //setErrorMsg(data.message);
            }
        } catch (error) {
            setErrorPage(true);
            setErrorMsg(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (activeTab && !isSearching) {
            setFilteredPlans(plansData[activeTab] || []);
        }
    }, [activeTab, plansData, isSearching]);

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchValue(value);

        if (value.trim() === "") {
            setIsSearching(false);
            setFilteredPlans(plansData[activeTab] || []);
        } else {
            setIsSearching(true);
            const allPlans = Object.values(plansData).flat();
            const filtered = allPlans.filter((plan) => plan.amount.toString().includes(value.trim()));
            setFilteredPlans(filtered);
        }
    };

    const handleContinue = async(amount) =>{
        setLoading(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}validation.php?key=${key}&operator=${operator_id}&circle=${circle_id}&number=${number}&amount=${amount}`);
            if (!response.ok) throw new Error('Failed to fetch form inputs');
            const data = await response.json();

            if (data.status === 'Success') {
                if(data.data1 === 'validated'){
                    Swal.fire({
                        title: 'Are you sure?',
                        html: `Do you want to proceed with the number and plan?<br><br>Number : <b>${number}</b><br>Amount : <b>${amount}</b>`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Confirmed',
                        cancelButtonText: 'Cancel'
                    }).then(async(result) => {
                        if (result.isConfirmed) {
                            setTransactionProcessing(true);
                            try {
                                const key = localStorage.getItem('key');
                                const response = await fetch(`${API_URL}universal_payment.php?key=${key}&operator_id=${operator_id}&amount=${amount}&number=${number}`);
                                if (!response.ok) throw new Error('Failed to fetch form inputs');
                                const data = await response.json();
                    
                                if (data.status === 'Success') {
                                    setOrderId(data.data2);
                                    setTxnId(data.data1);
                                    setTransactionSuccess(true);
                                } else if (data.status === 'Failed') {
                                    setOrderId(data.data2);
                                    setTxnId(data.data1);
                                    setTxnMsg(data.message);
                                    setTransactionFailed(true);
                                }else {
                                    setOrderId(data.data2);
                                    setTxnId(data.data1);
                                    setTransactionPending(true);
                                }
                            } catch (error) {
                                setOrderId('');
                                setTxnId('');
                                setTransactionPending(true);
                            } finally{
                                setTransactionProcessing(false);
                            }
                        } else {
                            // Action was canceled
                            console.log('Action canceled!');
                        }
                    });
                }else{
                    Swal.fire('Failed', data.data2, 'error');
                }
            } else {
                setErrorPage(true);
                setErrorMsg(data.message);
            }
        } catch (error) {
            setErrorPage(true);
            setErrorMsg(error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            {loading ? (
                <div className="loading_center">
                    <div className="loading"></div>
                </div>
            ) : errorPage ? (
                <ErrorPage message={errorMsg} callback={() => fetchOperatorState(number)} />
            ) : transactionProcessing ? (
                <TransactionProcessing/>
            ) : transactionPending ? (
                <TransactionPending transactionId={txn_id} orderId={order_id} txnMsg=""/>
            ) : transactionSuccess ? (
                <TransactionSucess transactionId={txn_id} orderId={order_id} txnMsg=""/>
            ) : transactionFailed ? (
                <TransactionFailed transactionId={txn_id} orderId={order_id} txnMsg={txn_msg}/>
            ) : (
                <div>
                    <HeaderActivity title="Recharge Plan" />
                    <div className='page-container' >
                        <div className='form_div'>
                            <input
                                type="text"
                                name="number"
                                value={number}
                                className="form-control"
                                required
                                readOnly
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center' }}>
                                <select className="form-control-select" style={{ maxWidth: '50%' }}
                                    value={operator_id}
                                    onChange={(e) => setOperatorId(e.target.value)}>
                                    <option value="" disabled>--Select Operator--</option>
                                    <option value="100">Airtel</option>
                                    <option value="101">Jio</option>
                                    <option value="102">Vi</option>
                                    <option value="103">BSNL</option>
                                </select>
                                <select className="form-control-select" style={{ maxWidth: '50%' }}
                                    value={circle_id}
                                    onChange={(e) => setCircleId(e.target.value)}>
                                    <option value="" disabled>--Select Circle--</option>
                                    <option value="1">Andhra Pradesh</option>
                                    <option value="2">Assam</option>
                                    <option value="3">Bihar & Jharkhand</option>
                                    <option value="3">Bihar Jharkhand</option>
                                    <option value="4">Chennai</option>
                                    <option value="5">Delhi & NCR</option>
                                    <option value="5">Delhi NCR</option>
                                    <option value="6">Gujarat</option>
                                    <option value="7">Haryana</option>
                                    <option value="8">Himachal Pradesh</option>
                                    <option value="9">Jammu & Kashmir</option>
                                    <option value="10">Karnataka</option>
                                    <option value="11">Kerala</option>
                                    <option value="12">Kolkata</option>
                                    <option value="13">Maharashtra & Goa (except Mumbai)</option>
                                    <option value="13">Maharashtra</option>
                                    <option value="13">Goa</option>
                                    <option value="14">MP & Chhattisgarh</option>
                                    <option value="14">MP</option>
                                    <option value="14">Chhattisgarh</option>
                                    <option value="14">Madhya Pradesh Chhattisgarh</option>
                                    <option value="14">Madhya Pardesh</option>
                                    <option value="15">Mumbai</option>
                                    <option value="16">North East</option>
                                    <option value="17">Orissa</option>
                                    <option value="18">Punjab</option>
                                    <option value="19">Rajasthan</option>
                                    <option value="20">Tamilnadu</option>
                                    <option value="20">Tamil Nadu</option>
                                    <option value="20">Telangana</option>
                                    <option value="21">UP (East)</option>
                                    <option value="21">UP East</option>
                                    <option value="22">UP (West) & Uttarakhand</option>
                                    <option value="22">UP West</option>
                                    <option value="22">Uttarakhand</option>
                                    <option value="23">West Bengal</option>
                                    <option value="51">All India (except Delhi/Mumbai)</option>
                                    <option value="51">All Circles</option>
                                </select>
                            </div>
                            <input
                                type="number"
                                name="amount"
                                placeholder='Search amount'
                                className="form-control"
                                autoComplete='off'
                                value={searchValue}
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                    <div >
                        {!isSearching && (
                            <div
                                style={{
                                    display: 'flex',
                                    overflowX: 'auto',
                                    whiteSpace: 'nowrap',
                                    gap: '7px',
                                    padding: '5px 0',
                                    margin: '0px 10px',
                                    scrollbarWidth: 'none', /* Firefox */
                                    msOverflowStyle: 'none', /* IE and Edge */
                                }}
                            >
                                {Object.keys(plansData).map((tab) => (
                                    <button key={tab}
                                        style={{
                                            padding: '13px 10px',
                                            border: 'none',
                                            borderRadius: '5px',
                                            minWidth: '100px',
                                            flexShrink: 0,
                                            backgroundColor: activeTab === tab ? '#007bff' : '#fff', // Primary for active tab
                                            color: activeTab === tab ? '#fff' : '#000', // Text color for active tab
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setActiveTab(tab)}>
                                        {tab}
                                    </button>
                                ))}
                            </div>
                        )}
                        {loading ? (
                            <div className="loading_center">
                                <div className="loading"></div>
                            </div>
                        ) : (
                            <div style={{ margin: '0px 10px' }}>
                                {filteredPlans.map((plan, index) => (
                                    <div key={index} onClick={() => handleContinue(plan.amount)}  style={{ backgroundColor: '#fff', marginBottom: '5px', marginTop: '5px', padding: '10px', borderRadius: '5px' }}>
                                        <div>
                                            <div>
                                                <span>Amount : </span>
                                                <b>{plan.amount}</b>
                                            </div>
                                            <div>
                                                <span>Data : </span>
                                                <b>{plan.Data}</b>
                                            </div>
                                            <div>
                                                <span>Validity : </span>
                                                <b>{plan.Validity}</b>
                                            </div>
                                        </div>
                                        <p>{plan.planDescription}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )
            }

        </div>
    )
}

export default RechargePlan