import React, { useState, useEffect } from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import BarChartIcon from '@mui/icons-material/BarChart';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import '../style/com-transaction-sumary.css';

const API_URL = process.env.REACT_APP_API_URL;

const TransactionSummary = () => {
  const [balances, setBalances] = useState({}); // Initialized as an object

  useEffect(() => {
    const key = localStorage.getItem('key');
    const fetchBalance = async () => {
      try {
        const response = await fetch(`${API_URL}profile_report.php?key=${key}`);
        const data = await response.json();
        if (data.status === 'Success') {
          setBalances(data); // Assuming the API returns an object
          localStorage.setItem('note', data.data2 || '');
        } else {
          Swal.fire('Ohh no', data.message, 'error');
        }
      } catch (err) {
        Swal.fire('Ohh no', 'Something went wrong.', 'error');
      }
    };

    fetchBalance();
  }, []);

  return (
    <div>
      <div className='home-balance-card'>
        <div className='home-balance-left'>
          <WalletIcon fontSize='large' />
          <div className='card-profile-header'>
            <p>Balance</p>
            <p>{balances?.data3 || '0.00'}</p>
          </div>
        </div>
        <Link to='../top-up' style={{ color: 'black', textDecoration: 'none' }} className='home-balance-right'>
          <div className='home-balance-left-button'>
            <AddCircleIcon fontSize='small' />
            <p>Add Money</p>
          </div>
        </Link>
      </div>
      <div className='two-part'>
        <div className='card-profile' style={{ width: '50%', backgroundColor: 'var(--app-color)', color: 'white' }}>
          <VerifiedIcon />
          <div className='card-profile-header'>
            <p>Total Recharge</p>
            <p>{balances?.data1 || '0.00'}</p>
          </div>
        </div>
        <div className='card-profile' style={{ width: '50%', backgroundColor: 'var(--app-color)', color: 'white' }}>
          <BarChartIcon />
          <div className='card-profile-header'>
            <p>Earned</p>
            <p>
              {Number(balances?.data2 || 0) + Number(balances?.data4 || 0)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionSummary;
