import React from 'react'
import HeaderActivity from '../components/HeaderActivity'

const ContactUs = () => {
  return (
    <div>
      <HeaderActivity title='Contact us' />
      <div className='page-container'>
        <div style={{ margin: '10px' }}>
          <h3 style={{ color: 'var(--app-color)', marginBottom: '5px', textDecoration: 'underline', marginTop: '10px' }}>Contact Information</h3>
          <ul style={{ listStyleType: 'none' }}>
            <li>Email : round2pay.help@gmail.com</li>
            <li>Phone : (+91) 9365773618</li>
            <li>Address : Dalgaon Town, Darrang, IN 784116</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ContactUs