import React, { useState } from 'react';
import '../style/login.css';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const API_URL = process.env.REACT_APP_API_URL;

function Login() {
    const navigate = useNavigate();
    const [formValue, setFormValue] = useState({
        number: '',
        password: '',
        agreed: false
    });
    const [loginBtn, setLoginBtn] = useState(false);

    /** Handle Input Change */
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormValue({
            ...formValue,
            [name]: newValue,
        });
    };

    /** Handle Login Submission */
    const handleLogin = (event) => {
        event.preventDefault();
        if (formValue.number.length === 10 && formValue.agreed) {
            sendOtp(formValue.number);
        } else {
            toast.error('Please enter a valid number, password, and agree to the terms.');
        }
    };

    /** Send OTP */
    async function sendOtp(number) {
        setLoginBtn(true);
        try {
            const response = await fetch(`${API_URL}login.php?number=${number}&token=1222`);
            const data = await response.json();

            if (data.status === 'Failed') {
                if (data.suggestion === 'register') {
                    Swal.fire({
                        title: 'Register?',
                        text: data.message,
                        icon: 'question',
                        confirmButtonText: 'Register',
                        cancelButtonText: 'Cancel',
                        showCancelButton: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('../register', { state: { number } });
                        }
                    });
                } else {
                    Swal.fire('Ohh no', data.message, 'error');
                }
            } else {
                navigate('../otp-verification', { state: { number } });
            }
        } catch (error) {
            Swal.fire('Ohh no', 'Something went wrong.', 'error');
        } finally {
            setLoginBtn(false);
        }
    }

    const checkPolicyPages = (event) => {
        navigate(event.target.getAttribute('url'));
    };

    return (
        <div className='login-body'>
            {/* Header Section */}
            <div className="login-header-item">
                <h1 className="login-title">Welcome Back!</h1>
                <h2 className="login-subtitle">Sign in to continue</h2>
            </div>

            {/* Login Form */}
            <div className="login-container">
                <form className="login-form" onSubmit={handleLogin}>
                    {/* Mobile Number */}
                    <label htmlFor="number" className="login-label">Mobile Number</label>
                    <input
                        type="number"
                        name="number"
                        value={formValue.number}
                        placeholder="Enter mobile number"
                        className="form-control"
                        autoComplete="off"
                        required
                        pattern="[6-9][0-9]{9}"
                        title="Mobile number must be a valid number."
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value) && value.length <= 10) {
                                handleChange(e);
                            }
                        }}
                    />

                    {/* Terms and Conditions */}
                    <div className="login-checkbox">
                        <input
                            type="checkbox"
                            name="agreed"
                            checked={formValue.agreed}
                            onChange={handleChange}
                            id="agreed"
                        />
                        <label htmlFor="agreed" className="login-checkbox-text">
                            I agree to the <span style={{color:'green'}} onClick={checkPolicyPages} url="/terms-condition">Terms & Conditions</span>
                        </label>
                    </div>

                    {/* Sign In Button */}
                    <button
                        type="submit"
                        className="login-signin-btn"
                        disabled={loginBtn}
                    >
                        {loginBtn ? <div className="login-signin-btn-loading"></div> : 'SIGN IN'}
                    </button>
                </form>

                {/* Sign Up Link */}
                <div className="login-signup-text">
                    Don’t have an account?
                    <Link to='../register' className="login-signup-link"> Register</Link>
                </div>
            </div>

            {/* Toast Notifications */}
            <Toaster
                position="bottom-center"
                toastOptions={{
                    duration: 3000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: { duration: 3000 },
                }}
            />
        </div>
    );
}

export default Login;
