import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function TransactionSucess({transactionId, orderId, txnMsg}) {
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Trigger animation after the component mounts
        setIsVisible(true);
    }, []);

    return (
        <div
            style={{
                height: '100vh',
                maxWidth: '100vw',
                backgroundColor: '#4BB543',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                color: 'white',
                padding: '10px',
                transform: isVisible ? 'translateX(0)' : 'translateX(100%)',
                transition: 'transform 0.5s ease-in-out'
            }}
        >
            <div style={{ margin: '15px auto' }}><CheckCircleIcon style={{ height: '100px', width: '100px' }} /></div>
            <p style={{ fontSize: '22px' }}>Transaction is succesful</p>
            <p style={{ fontSize: '16px', marginTop: '15px' }}>Txn Id : {transactionId}</p>
            <p style={{
                fontSize: '16px',
                marginTop: '5px',
                textAlign: 'center'
            }}>
                For any grievances, please navigate to the "View Receipt" section and raise a complaint.
            </p>
            <hr style={{ height: '1px', width: '100vw', margin: '20px', backgroundColor: 'white', color: 'white' }}></hr>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <Button style={{ padding: '10px', backgroundColor: '#206bc4' }} onClick={() => navigate(`/invoice?orderId=${orderId}`)}>View Receipt</Button>
                <Button style={{ padding: '10px', backgroundColor: '#f50057' }} onClick={() => navigate(-1)}>Close</Button>
            </div>
        </div>
    );
}

export default TransactionSucess