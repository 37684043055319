import React, { useEffect, useState } from 'react';
import Menu from '../components/Menu';
import Header from '../components/Header';
import '../style/margin.css';
import ErrorPage from '../components/ErrorPage';

const API_URL = process.env.REACT_APP_API_URL;

const Margin = () => {
  document.title = "Margin";
  const [transactions, setTransactions] = useState([]);  // Holds all fetched transactions
  const [filteredTransactions, setFilteredTransactions] = useState([]); // Holds filtered data
  const [loading, setLoading] = useState(false);
  const [error_page, setError] = useState(false);
  const [error_msg, setErrorMsg] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const fetchTransactions = async () => {
    const key = localStorage.getItem('key');
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}operator.php?key=${key}&sub_category=Non BBPS`);
      const data = await response.json();
      if (data.data1.length > 0) {
        setTransactions(data.data1);  // Store all transactions
        setFilteredTransactions(data.data1);  // Initially, show all transactions
      }
      setError(false);
      setErrorMsg('');
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();  // Fetch transactions once when the component mounts
  }, []);

  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchQuery(input); // Set the search query

    const filteredData = transactions.filter((item) => 
      item.biller_name.toLowerCase().includes(input.toLowerCase()) || 
      item.biller_category.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredTransactions(filteredData);
  };

  return (
    <div>
      {error_page ? (
        <ErrorPage message={error_msg} callback={fetchTransactions} />
      ) : (
        <div>
          <Header />
          <Menu />
          <div className='main-container'>
            <div style={{ padding: '6px 10px 0px' }}>
              <input
                type="text"
                placeholder='Search here...'
                className='form-control'
                autoComplete='off'
                required
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <div className='table_div'>
              {filteredTransactions.map((item) => (
                <div className='margin_card' key={item.operator_id}>
                  <div>
                    <img src={item.operator_logo} alt='biller' className="company-logo" />
                  </div>
                  <div className='biller_info'>
                    <div className="biller_name">{item.biller_name}</div>
                    <div className="biller_mode">{item.biller_category}</div>
                  </div>
                  <div className="margin">{parseFloat(item.commission).toFixed(2) + " " + item.commission_type}</div>
                </div>
              ))}
              {loading && <center className="loading"></center>}
              {!filteredTransactions.length && !loading && <center>No transactions found.</center>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Margin;
