import React, { useState } from 'react';
import '../style/login.css';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const API_URL = process.env.REACT_APP_API_URL;

const OtpVerification = () => {
    const navigate = useNavigate();
    const [loginbtn, setLoginBtn] = useState(false);
    const location = useLocation();
    const { number } = location.state || {};
    console.log(number);

    const [formValue, setFormValue] = useState({
        number: number,
        otp: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValue({
            ...formValue,
            [name]: value,
        });
    };

    const HandleLogin = (event) => {
        event.preventDefault();
        if (formValue.otp.length === 6) {
            VerifyOtp(formValue.otp);
        } else {
            toast.error('Enter valid OTP.');
        }
    };

    async function VerifyOtp(otp) {
        setLoginBtn(true);
        try {
            const response = await fetch(API_URL + 'verify_otp.php?number=' + formValue.number + '&otp=' + formValue.otp);
            const data = await response.json();
            if (data.status === 'Failed') {
                Swal.fire('Ohh no', data.message, 'error');
            } else {
                localStorage.setItem('key', data.data1);
                localStorage.setItem('name', data.data2);
                localStorage.setItem('number', data.data3);
                localStorage.setItem('refer_code', data.data4);
                window.location.replace('../home');
            }
        } catch (error) {
            Swal.fire('Ohh no', 'Something went wrong.', 'error');
        } finally {
            setLoginBtn(false);
        }
    }

    return (
        <div className='login-body'>
            <div className="login-header-item">
                <h1 className="login-title">OTP sent</h1>
                <h2 className="login-subtitle">+91-{number}</h2>
            </div>
            <div className="login-container">
                <form className="login-form" onSubmit={HandleLogin}>
                    <label htmlFor="otp" className="login-label">Enter OTP</label>
                    <input
                        type="number"
                        name="otp"
                        value={formValue.otp}
                        placeholder='Enter your OTP'
                        className='form-control'
                        autoComplete='off'
                        required
                        pattern="[0-9]{6}"
                        title="OTP must be a 6 digit number."
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value) && value.length <= 6) {
                                handleChange(e);
                            }
                        }}
                    />
                    <button
                        type="submit"
                        className="login-signin-btn"
                        disabled={loginbtn}
                    >
                        {loginbtn ? <div className="login-signin-btn-loading"></div> : 'VERIFY'}
                    </button>
                </form>
                <div className="login-signup-text">
                    Mobile number change?{' '}
                    <span
                        onClick={() => navigate(-1)}
                        className="login-signup-link"
                        style={{ cursor: 'pointer' }}
                    >
                        Change
                    </span>
                </div>
            </div>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                toastOptions={{
                    duration: 3000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: {
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
        </div>
    );
};

export default OtpVerification;
