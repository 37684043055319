import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Report from './pages/report';
import Margin from './pages/margin';
import Login from './pages/login';
import Registered from './pages/register';
import Home from './pages/home';
import Profile from './pages/profile';
import Setting from './pages/setting';
import MobilePrepaidRecharge from './pages/mobile-prepaid-recharge';
import TopUp from './pages/top-up';
import Aboutus from './pages/about-us';
import ContactUs from './pages/contact-us';
import TermsService from './pages/terms-service';
import CheckOutPage from './pages/check-out';
import CustomPaymentRespnse from './pages/custom-payment-response';
import PrivacyPolicy from './pages/privacy-policy';
import RefundPolicy from './pages/refund-policy';
import OtpVerification from './pages/otp-verification';
import InstallApp from './pages/install';
import BbpsBiller from './pages/operator';
import BbpsForm from './pages/operator-form';
import Services from './pages/service';
import WalletTransfer from './pages/wallet-transfer';
import RechargePlan from './pages/mobile-recharge-plan';
import { isAuthenticated } from './credentials/isAuthenticated';
import AccountDelete from './pages/account-delete';
import Invoice from './pages/invoice';
import BackPageRedirect from './pages/back-page-redirect';
import './index.css'



export default function App() {

  const isAuth = isAuthenticated();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={isAuth ? <Home /> : <Navigate to="/login" replace />}></Route>
        <Route path="*" element={isAuth ? <Home /> : <Navigate to="/login" replace />}></Route>
        <Route path='report' element={isAuth ? <Report /> : <Navigate to="/login" replace />}></Route>
        <Route path='margin' element={isAuth ? <Margin /> : <Navigate to="/login" replace />}></Route>
        <Route path='home' element={isAuth ? <Home /> : <Navigate to="/login" replace />}></Route>
        <Route path='setting' element={isAuth ? <Setting /> : <Navigate to="/login" replace />}></Route>
        <Route path='profile' element={isAuth ? <Profile /> : <Navigate to="/login" replace />}></Route>
        <Route path='mobile-prepaid-recharge' element={isAuth ? <MobilePrepaidRecharge /> : <Navigate to="/login" replace />}></Route>
        <Route path='mobile-recharge-plan' element={isAuth ? <RechargePlan /> : <Navigate to="/login" replace />}></Route>
        <Route path='top-up' element={isAuth ? <TopUp /> : <Navigate to="/login" replace />}></Route>
        <Route path='operator' element={isAuth ? <BbpsBiller /> : <Navigate to="/login" replace />}></Route>
        <Route path='operator-form' element={isAuth ? <BbpsForm /> : <Navigate to="/login" replace />}></Route>
        <Route path='check-out' element={isAuth ? <CheckOutPage /> : <Navigate to="/login" replace />}></Route>
        <Route path='payment-response' element={isAuth ? <CustomPaymentRespnse /> : <Navigate to="/login" replace />}></Route>
        <Route path='service' element={isAuth ? <Services /> : <Navigate to="/login" replace />}></Route>
        <Route path='invoice' element={isAuth ? <Invoice /> : <Navigate to="/login" replace />}></Route>
        <Route path='wallet-transfer' element={isAuth ? <WalletTransfer /> : <Navigate to="/login" replace />}></Route>
        <Route path='redirect-page' element={isAuth ? <BackPageRedirect /> : <Navigate to="/login" replace />}></Route>

        <Route path='otp-verification' element={isAuth ? <Navigate to="/home" replace /> : <OtpVerification />}></Route>
        <Route path='login' element={isAuth ? <Navigate to="/home" replace /> : <Login />}></Route>
        <Route path='register' element={isAuth ? <Navigate to="/home" replace /> : <Registered />}></Route>
        
        <Route path='install' element={<InstallApp />}></Route>
        <Route path='about-us' element={<Aboutus />}></Route>
        <Route path='contact-us' element={<ContactUs />}></Route>
        <Route path='terms-condition' element={<TermsService />}></Route>
        <Route path='privacy-policy' element={<PrivacyPolicy />}></Route>
        <Route path='refund-policy' element={<RefundPolicy />}></Route>
        <Route path='account-deletion' element={<AccountDelete />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
