import React, { useState, useEffect } from 'react';
import HeaderActivity from '../components/HeaderActivity';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';

const Recharge = () => {
    const [number, setNumber] = useState('');
    const navigate = useNavigate();

    // Handle input change for the number
    const handleChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setNumber(value);
        }
    };

    const HandleContinueForm = (event) => {
        event.preventDefault();
        console.log(number);

        if (number && /^[0-9]{10}$/.test(number)) {
            navigate(`/mobile-recharge-plan?number=${number}`);
        } else {
            toast.error('Enter a valid 10-digit mobile number.');
        }
    };

    // Function to trigger native contact picker
    const openContactPicker = () => {
        if (window.AndroidInterface) {
            window.AndroidInterface.openContactPicker();
        } else {
            toast.error('Contact list is not permissible.');
        }
    };

    // This will be called when Android returns the contact number
    useEffect(() => {
        const handleMessage = (event) => {
            const contactNumber = event.data;
            if (contactNumber) {
                console.log('Received contact number:', contactNumber); 
                setNumber(contactNumber); 
            } else {
                console.log('No contact number received.');
            }
        };
    
        window.addEventListener('message', handleMessage);
    
        // Clean up the listener when the component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
    

    return (
        <div>
            <HeaderActivity title="Mobile Recharge" />
            <div className='page-container'>
                <div className='form_div'>
                    <div style={{ position: 'relative' }}>
                        <input
                            id='number_id'
                            type="number"
                            name="number"
                            onChange={handleChange}
                            value={number}
                            placeholder="Enter number"
                            className="form-control"
                            autoComplete="off"
                            required
                            pattern="^[0-9]{10}$"
                            title="Please enter a valid 10-digit mobile number"
                        />
                        <PermContactCalendarOutlinedIcon
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                fontSize: '25px',
                                color: '#888', // Customize color
                                cursor: 'pointer',
                            }}
                            onClick={openContactPicker} // Trigger contact picker on icon click
                        />
                    </div>
                    <button onClick={HandleContinueForm} className='form-btn'>
                        <div className='btn-text'>Processed</div>
                    </button>
                </div>
                <div className='bg-white' style={{ padding: '10px', margin: '10px', borderRadius: '5px' }}>
                    <p style={{ textAlign: 'justify', fontSize: '14px' }}>
                        Please confirm the recharge number, operator, plan, and other input details before processing.
                        Once successfully done, refund request cannot be initiated.
                    </p>
                </div>
            </div>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 3000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
        </div>
    );
};

export default Recharge;
