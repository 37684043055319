import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';

const CheckOutPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { url, order_id } = location.state || {};
    const [status, setStatus] = useState('pending');
    const [timeRemaining, setTimeRemaining] = useState(180);
    const [canAlert, setCanAlert] = useState(true);

    const fetchStatus = async () => {
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${process.env.REACT_APP_API_URL}payment_status.php?key=${key}&order_id=${order_id}`);
            if (response.ok) {
                const data = await response.json();
                setStatus(data.status);
                if (data.status === 'Success') {
                    if (canAlert) {
                        setCanAlert(false);
                        Swal.fire({
                            title: 'Success',
                            text: 'Topup successfully done',
                            icon: 'success',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate(-1);
                            }
                        });
                    }
                } else if (data.status === 'failed') {
                    if (canAlert) {
                        setCanAlert(false);
                        Swal.fire({
                            title: 'Failed!',
                            text: 'Topup operation failed',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate(-1);
                            }
                        });
                    }
                }
            } else {
                Swal.fire({
                    title: 'Warning!',
                    text: 'The operation is still pending.',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(-1);
                    }
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Warning!',
                text: 'The operation is still pending.',
                icon: 'warning',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(-1);
                }
            });
        }
    };

    useEffect(() => {
        if (status !== 'pending') return; // If status is not 'pending', don't set up the interval.

        const interval = setInterval(() => {
            setTimeRemaining((prev) => {
                if (prev <= 1) {
                    clearInterval(interval);
                    if (status === 'pending') {
                        Swal.fire('Warning!', 'The operation is still pending after 3 minutes.', 'warning');
                    }
                    return 0;
                }
                return prev - 1;
            });

            // Call the API every 5 seconds while the timeRemaining is more than 0
            if (timeRemaining % 5 === 0) {
                fetchStatus();
            }
        }, 1000);

        return () => clearInterval(interval);  // Clear the interval on cleanup when status changes
    }, [status, timeRemaining, canAlert, order_id, navigate]); // Remove `status` from the dependencies so it doesn't keep triggering

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                {url ? (
                    <>
                        <QRCode value={url} size={180} bgColor="#ffffff" fgColor="#000000" />
                    </>
                ) : (
                    <p>Click below button</p>
                )}
            </div>
            <a
                style={{
                    textAlign: 'center',
                    textDecoration: 'none',
                    backgroundColor: 'var(--app-color)',
                    padding: '10px',
                    borderRadius: '5px',
                    color: 'white',
                    width: '80%',
                    marginBottom: '30px',
                    marginTop: '30px'
                }}
                href={url}
                className='cursor-pointer'
            >
                Pay now
            </a>
            <div style={{ textAlign: 'center' }}>
                <p>Time out: {formatTime(timeRemaining)}</p>
                {timeRemaining <= 0 && status === 'pending' ? (
                    <p>Status is still pending after 3 minutes.</p>
                ) : (
                    <p>Payment status: {status}</p>
                )}
            </div>
            <div style={{ marginTop: '50px', color: 'var(--app-color)', textAlign: 'center' }}>
                Don't close or refresh this window.
            </div>
        </div>
    );
};

export default CheckOutPage;
