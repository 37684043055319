import React, { useEffect, useState } from 'react';

function TransactionProcessing() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Trigger animation after the component mounts
        setIsVisible(true);
    }, []);

    return (
        <div
            style={{
                height: '100vh',
                maxWidth: '100vw',
                backgroundColor: '#026CC4',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                color: 'white',
                padding: '10px',
                transform: isVisible ? 'translateX(0)' : 'translateX(100%)',
                transition: 'transform 0.5s ease-in-out'
            }}
        >
            <div className='loading-transaction' ></div>
            <p style={{ fontSize: '22px' }}>Transaction is processing...</p>
            <p style={{
                fontSize: '16px',
                marginTop: '10px',
                textAlign: 'center'
            }}>
                Your transaction is under processing, Please wait for sometime and do not close or back the application.
            </p>
        </div>
    );
}

export default TransactionProcessing;
