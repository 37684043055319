import React, { useEffect, useState, useCallback } from 'react';
import '../style/invoice.css';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ErrorPage from '../components/ErrorPage';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import html2canvas from 'html2canvas';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';

const API_URL = process.env.REACT_APP_API_URL;

const Invoice = () => {
    const [loading, setLoading] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const [orderId, setOrderId] = useState('');
    const [orderInfo, setOrderInfo] = useState([]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const orderId = queryParams.get('orderId');
        if (!orderId) {
            Swal.fire({
                title: 'Transaction Failed',
                text: 'Order id not found or mismatched.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
                confirmButtonColor: '#3085d6',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(-1);
                }
            });
        } else {
            setOrderId(orderId);
        }
    }, [location.search, navigate]);

    // Memoize the getOrderInfo function with useCallback to avoid unnecessary re-renders
    const getOrderInfo = useCallback(async () => {
        console.log('Refreshing...');
        setLoading(true);
        const key = localStorage.getItem('key');
        try {
            const response = await fetch(`${API_URL}order_info.php?key=${key}&order_id=${orderId}`);
            const data = await response.json();
            if (data.status === 'Success') {
                console.log(data.data1);
                setOrderInfo(data.data1);
                setErrorPage(false);
            } else {
                setErrorPage(true);
                setErrorMsg(data.message);
            }
        } catch (err) {
            setErrorPage(true);
            setErrorMsg(err.message);
        } finally {
            setLoading(false);
        }
    }, [orderId]);

    useEffect(() => {
        if (orderId) {  
            getOrderInfo();
        }
    }, [orderId, getOrderInfo]);

    const getTransactionStatus = () => {
        if (orderInfo.status === "1") {
            return 'Transaction Successful';
        } else if (orderInfo.status === "2") {
            return 'Transaction Failed';
        } else {
            return 'Transaction Pending';
        }
    };

    const getStatusStyle = () => {
        if (orderInfo.status === "1") {
            return { backgroundColor: '#4BB543' };
        } else if (orderInfo.status === "2") {
            return { backgroundColor: '#DC3545' };
        } else {
            return { backgroundColor: '#FFC107' };
        }
    };

    const getStatusIcon = () => {
        if (orderInfo.status === "1") {
            return <CheckCircleIcon style={{ height: '100px', width: '100px', color: 'white' }} />;
        } else if (orderInfo.status === "2") {
            return <CancelIcon style={{ height: '100px', width: '100px', color: 'white' }} />;
        } else {
            return <PendingIcon style={{ height: '100px', width: '100px', color: 'white' }} />;
        }
    };

    const raiseComplaint = (event) => {
        Swal.fire({
            title: 'Enter your query',
            input: 'text',
            inputValue: 'Default',
            showCancelButton: true,
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const inputText = result.value;
                if (inputText !== "") {
                    const key = localStorage.getItem('key');
                    try {
                        const response = await fetch(`${API_URL}complaint.php?key=${key}&order_id=${orderInfo.order_id}&message=${inputText}&reason=Complaint`);
                        const data = await response.json();

                        if (data.status === 'Success') {
                            Swal.fire('Success', data.message, 'success');
                        } else {
                            Swal.fire('Failed', data.message, 'error');
                        }
                    } catch (error) {
                        Swal.fire('Ohh no', 'Something went wrong.', 'error');
                    }
                } else {
                    toast.error('Enter valid input.');
                }
            }
        });
    };

    const handleDownload = async () => {
        toast.success('Downloading...');

        const element = document.documentElement; // capture the entire page
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = `invoice_${orderInfo.order_id}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            {loading ? (
                <div className="loading_center">
                    <div className="loading"></div>
                </div>
            ) : errorPage ? (
                <ErrorPage message={errorMsg} callback={getOrderInfo} />
            ) : (
                orderInfo && (
                    <div id="invoice-content">
                        <div className="status_div" style={getStatusStyle()}>
                            <div className='error-back-button' style={{ color: 'white', zIndex: '999' }} onClick={() => navigate(-1)}><ArrowBackIcon /> Back</div>
                            <div className="center_container">
                                {getStatusIcon()}
                                <p>{getTransactionStatus()}</p>
                            </div>
                            <div className='transaction_heading'>
                                <p>Amount: ₹ <b style={{ fontSize: '20px' }}>{orderInfo.amount}</b></p>
                                <p>Number: {orderInfo.number ? (orderInfo.number) : ('NA')}</p>
                                <p>Txn No: {orderInfo.transaction_id ? (orderInfo.transaction_id) : ('NA')}</p>
                            </div>
                        </div>
                        <div className='order_details'>
                            <div className='share_row'>
                                <p><b>From: Wallet</b></p>
                                <DownloadIcon onClick={handleDownload} />
                            </div>
                            <p>Number: {orderInfo.number ? (orderInfo.number) : ('NA')}</p>
                            <p>Name: {orderInfo.user_name ? (orderInfo.user_name) : ('NA')}</p>
                            <p>Time: {orderInfo.createAt}</p>
                            <p>Biller: {orderInfo.biller_name ? (orderInfo.biller_name) : ('NA')}</p>
                            <p>Transaction: {orderInfo.sub_category ? (orderInfo.sub_category) : ('NA')}</p>
                            <p>Order Id: {orderInfo.order_id ? (orderInfo.order_id) : ('NA')}</p>
                        </div>
                        <div className='order_rewards'>
                            <p><EmojiEventsIcon /> Rewards</p>
                            <div>₹ {orderInfo.user_profit ? (orderInfo.user_profit) : (0.00)}</div>
                        </div>
                        <div className='order_dispute' onClick={() => raiseComplaint()}>
                            <p><AnnouncementIcon /> Raise Complaint</p>
                        </div>
                        <div className='order_dispute' onClick={() => window.location.href = `https://wa.me/917578931458?text=*Support for the query* \n${orderInfo.number}`}>
                            <p><WhatsAppIcon className='whatsapp_icon' /> WhatsApp Support</p>
                        </div>
                        <Toaster
                            position="bottom-center"
                            reverseOrder={false}
                            gutter={8}
                            containerClassName=""
                            containerStyle={{}}
                            toastOptions={{
                                className: '',
                                duration: 3000,
                                style: {
                                    background: '#363636',
                                    color: '#fff',
                                },
                                success: {
                                    duration: 3000,
                                    theme: {
                                        primary: 'green',
                                        secondary: 'black',
                                    },
                                },
                            }}
                        />
                    </div>
                )
            )}
        </div>
    );
};

export default Invoice;
