import React, { useState, useEffect } from 'react';
import Menu from '../components/Menu';
import Header from '../components/Header';
import Carousel from '../components/Carousel';
import { Link, useNavigate } from 'react-router-dom';
import TransactionSummary from '../components/TransactionSummary';
import ShareLayout from '../components/ShareLayout';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import GasMeterOutlinedIcon from '@mui/icons-material/GasMeterOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import billImage from '../assets/bill_image.png';
import rechargeImage from '../assets/recharge_image.png';
import upgrade1Image from '../assets/upgrade_1.png';
import upgrade2Image from '../assets/upgrade_2.png';

const API_URL = process.env.REACT_APP_API_URL;

const Home = () => {
  document.title = 'Home';

  const [note, setNote] = useState('');
  const [name, setName] = useState('');
  const navigator = useNavigate();

  // ✅ Fixed: Added dependency array to prevent infinite loop
  useEffect(() => {
    setNote('All operator working fine. For any query contact us.');
    setName(localStorage.getItem('name') || 'User');
  }, []);

  async function UpgradePayment(amount) {
    toast.loading('Loading...', {
      duration: Infinity,
      position: 'top-right',
    });
    try {
      const key = localStorage.getItem('key');
      const response = await fetch(`${API_URL}other_top_up.php?token=${key}&version=1&amount=${amount}&desc=upgrade`);
      const data = await response.json();
      if (data.status === 'Failed') {
        Swal.fire('Failed', data.message, 'error');
      } else {
        navigator('../check-out', { state: { url: data.url, order_id: data.order_id } });
      }
    } catch (error) {
      Swal.fire('Ohh no', 'Something went wrong.', 'error');
    } finally {
      toast.dismiss();
    }
  }

  function HandleUpgrade(event) {
    Swal.fire({
      title: 'Info!',
      text: 'Do you want to upgrade?',
      icon: 'info',
      confirmButtonText: 'Pay',
      cancelButtonText: 'Cancel',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        UpgradePayment(event);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your upgrade request was not completed', 'info');
      }
    });
  }

  return (
    <div>
      <Header />
      <Menu />
      <div className='main-container'>
        <div className='home-dashboard'>
          <center className='home-dashboard-content'>
            <p style={{ fontSize: '16px' }}>Hi, {name}</p>
            <p style={{ fontSize: '12px' }}>Welcome to Round2Pay</p>
            <hr className='home-dashboard-divider' />
            {/* ✅ Replaced <marquee> with a scrolling div */}
            <div style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              boxSizing: 'border-box',
            }}>
              <div style={{
                display: 'inline-block',
                paddingLeft: '100%',
                animation: 'scrollText 10s linear infinite',
              }}>
                {note}
              </div>
              <style>
                {`
                  @keyframes scrollText {
                    0% { transform: translateX(0); }
                    100% { transform: translateX(-100%); }
                  }
                `}
              </style>
            </div>
          </center>
        </div>
        <div className='home-dashboard-space'></div>
        <TransactionSummary />
        <div className='service-card-item'>
          <div className='service-card-item-header'>
            <span className='card-service-header-bar'></span>
            <p><b>Services</b></p>
          </div>
          <div className='service-item-row'>
            <Link to='../mobile-prepaid-recharge?title=Mobile Recharge' className='service-item-card'>
              <div className='service-item-content'>
                <PhoneAndroidOutlinedIcon style={{ fontSize: '31px' }} />
              </div>
              <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Mobile Prepaid</p>
            </Link>
            <Link to='../operator?category=Mobile Postpaid&title=Mobile Postpaid' className='service-item-card'>
              <div className='service-item-content'>
                <PhoneIphoneOutlinedIcon style={{ fontSize: '31px' }} />
              </div>
              <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Mobile Postpaid</p>
            </Link>
            <Link to='../operator?category=DTH&title=DTH Recharge' className='service-item-card'>
              <div className='service-item-content'>
                <LiveTvOutlinedIcon style={{ fontSize: '31px' }} />
              </div>
              <p style={{ fontSize: '11px', fontWeight: 'bold' }}>DTH</p>
            </Link>
            <Link to='../operator?category=Redeem Code&title=Reedem Code' className='service-item-card'>
              <div className='service-item-content'>
                <SmartDisplayOutlinedIcon style={{ fontSize: '31px' }} />
              </div>
              <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Redeem Code</p>
            </Link>
          </div>
          <div className='service-item-row'>
            <Link to='../operator?category=Electricity&title=Electricity' className='service-item-card'>
              <div className='service-item-content'>
                <LightbulbOutlinedIcon style={{ fontSize: '31px' }} />
              </div>
              <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Electricity</p>
            </Link>
            <Link to='../operator?category=Fastag&title=Fastag' className='service-item-card'>
              <div className='service-item-content'>
                <DirectionsCarOutlinedIcon style={{ fontSize: '31px' }} />
              </div>
              <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Fastag</p>
            </Link>
            <Link to='../operator?category=LPG Gas&title=LPG Gas' className='service-item-card'>
              <div className='service-item-content'>
                <GasMeterOutlinedIcon style={{ fontSize: '31px' }}/>
              </div>
              <p style={{ fontSize: '11px', fontWeight: 'bold' }}>LPG Gas</p>
            </Link>
            <Link to='../service' className='service-item-card'>
              <div className='service-item-content'>
                <AppsIcon style={{ fontSize: '31px' }} />
              </div>
              <p style={{ fontSize: '11px', fontWeight: 'bold' }}>Bill Pay</p>
            </Link>
          </div>
        </div>
        <Carousel />
        <div className='home-spotlight'>
          <div className='home-spotlight-container'>
            <Link to='../recharge?type=prepaid&title=Prepaid' style={{ marginLeft: '0px' }} className='home-spotlight-item home-bg-spotlight-1'>
              <div className='home-header-icon'>
                <div></div>
                <img src={rechargeImage} alt='' loading="lazy" className="lazy-load-image" />
              </div>
              <h5>Recharge and earn topup 5% cashback.</h5>
              <p>Tap and recharge using our application or website.</p>
            </Link>
            <div onClick={HandleUpgrade.bind(this, '10000')} className='home-spotlight-item home-bg-spotlight-2'>
              <div className='home-header-icon'>
                <div></div>
                <img src={upgrade2Image} alt='' loading="lazy" className="lazy-load-image" />
              </div>

              <h5>Maintain 10000, extra 1% increment.</h5>
              <p>Tap and pay the amount to upgrade your id. Limit: 10000/Day.</p>
            </div>
            <Link to='../coming-soon' className='home-spotlight-item home-bg-spotlight-3'>
              <div className='home-header-icon'>
                <div></div>
                <img src={upgrade1Image} alt='' loading="lazy" className="lazy-load-image" />
              </div>
              <h5>Lighting speed billpay & earn upto 0.7</h5>
              <p>Tap and pay your bill using our application or website.</p>
            </Link>
            <div onClick={HandleUpgrade.bind(this, '500')} style={{ marginRight: '0px' }} className='home-spotlight-item home-bg-spotlight-4'>
              <div className='home-header-icon'>
                <div></div>
                <img src={billImage} alt='' loading="lazy" className="lazy-load-image" />
              </div>
              <h5>Upgrade to Golden retailer id. Fees 500.00</h5>
              <p>Explore dedicated QR, support. Free all features on future update.</p>
            </div>
          </div>
        </div>
        <div className='last-card'>
          <ShareLayout />
        </div>
      </div>
      <Toaster
        position='bottom-center'
        reverseOrder={false}
        toastOptions={{
          duration: 3000,
          style: { background: '#363636', color: '#fff' },
        }}
      />
    </div>
  );
};

export default Home;

