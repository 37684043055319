import React, { useEffect, useState, useRef, useCallback } from 'react';
import Menu from '../components/Menu';
import Header from '../components/Header';
import '../style/report.css';
import Balance from '../components/Balance';
import { useNavigate } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';

const API_URL = process.env.REACT_APP_API_URL;

const Report = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error_page, setError] = useState(false);
  const [error_msg, setErrorMsg] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQuery2, setSearchQuery2] = useState('');
  const observer = useRef();
  const typingTimeoutRef = useRef(null);

  const fetchTransactions = async (page, query = '') => {
    const key = localStorage.getItem('key');
    setLoading(true);
    try {
      // Make API request to fetch transactions, including search query and pagination params
      const response = await fetch(`${API_URL}report.php?key=${key}&limit=20&offset=${(page - 1) * 20}&number=${query}`);
      const data = await response.json();
      
      if (data.data1.length > 0) {
        setTransactions((prevTransactions) => [...prevTransactions, ...data.data1]);
      } else {
        setHasMore(false); // No more data to load
      }

      setError(false);
      setErrorMsg('');
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Reset the state for fresh search
    setTransactions([]);
    setPage(1);
    setHasMore(true);
    fetchTransactions(1, searchQuery2);
  }, [searchQuery2]);

  useEffect(() => {
    if (page === 1) return; // Don't call for the first page on initial load
    fetchTransactions(page, searchQuery);
  }, [page, searchQuery]);

  const lastTransactionElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [hasMore, loading]);

  const getStatusClass = (status) => {
    switch (status) {
      case 'Failed': return 'status-failed';
      case 'Success': return 'status-success';
      case 'Pending': return 'status-pending';
      default: return 'status-pending';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'Failed': return 'FAILED';
      case 'Success': return 'SUCCESS';
      case 'Pending': return 'PENDING';
      default: return 'INITIATE';
    }
  };

  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchQuery(input);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setSearchQuery(input); // Update search query after typing stops
      setSearchQuery2(input)
    }, 500); // Adjust the delay as needed
  };

  return (
    <div>
      {error_page ? (
        <ErrorPage message={error_msg} callback={() => fetchTransactions(page, searchQuery)} />
      ) : (
        <div>
          <Header />
          <Menu />
          <div className='main-container'>
            <div style={{ paddingTop: '10px' }}>
              <Balance />
            </div>
            <div style={{ padding: '0px 10px 0px' }}>
              <input
                value={searchQuery}
                type="text"
                name="number"
                placeholder='Search here..'
                className='form-control'
                autoComplete='off'
                required
                onChange={handleSearchChange}
              />
            </div>
            <div className='statement-card'>
              {transactions.map((transaction, index) => (
                <div
                  key={transaction.order_id}
                  ref={index === transactions.length - 1 ? lastTransactionElementRef : null}
                  className="transaction-item cursor-pointer"
                  onClick={() => navigate(`../invoice?orderId=${transaction.order_id}`)}
                >
                  <div className="icon-container">
                    <img src={transaction.operator_logo} alt='' className="icon" />
                  </div>
                  <div className="details">
                    <h3 className="title">{transaction.category} | {transaction.number}</h3>
                    <p className="description">
                      {transaction.createAt} |
                      <span className={getStatusClass(transaction.status)}>
                        {getStatusText(transaction.status)}
                      </span>
                    </p>
                  </div>
                  <div className="amount">{parseFloat(transaction.amount).toFixed(2)}</div>
                </div>
              ))}
              {loading && <center className="loading"></center>}
              {!hasMore && !loading && <center>No more transactions.</center>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Report;
