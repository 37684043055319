import React from 'react'
import Menu from '../components/Menu'
import Header from '../components/Header';
import TransactionSummary from '../components/TransactionSummary';
import ShareLayout from '../components/ShareLayout';
import ReportCard from '../components/ReportCard';
import GroupsIcon from '@mui/icons-material/Groups';
import MapIcon from '@mui/icons-material/Map';
import PolicyIcon from '@mui/icons-material/Policy';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GppGoodIcon from '@mui/icons-material/GppGood';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import LogoutIcon from '@mui/icons-material/Logout';
import DownloadIcon from '@mui/icons-material/Download';
import AccountIcon from '@mui/icons-material/AccountCircle';
import Swal from 'sweetalert2';


const Setting = () => {
  const handleLogout = (event) => {
    Swal.fire({
      title: 'Logout?',
      text: 'Are you really wants to logout?',
      icon: 'question',
      confirmButtonText: 'Logout',
      cancelButtonText: 'Cancel',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('key');
        localStorage.removeItem('profileData');
        localStorage.removeItem('referCode');
        localStorage.removeItem('note');
        window.location.replace('./login');
      }
    });
  }

  return (
    <div>
      <Header />
      <Menu />
      <div className='main-container'>
        <div style={{ paddingTop: '1px' }}>
          <TransactionSummary />
        </div>
        <ShareLayout />
        <ReportCard icon={<AccountIcon fontSize="large" />} title='Profile' url='../profile' desc="Update your name, email and address." />
        <a className='card-profile' href='./install'>
          <div className='text-app'>
            <DownloadIcon fontSize="large" />
          </div>
          <div className='card-profile-header'>
            <h4>Desktop App</h4>
            <p style={{ fontSize: '13px' }}>Install on any device. Laptop, Mobile etc.</p>
          </div>
        </a>
        <ReportCard icon={<GroupsIcon fontSize="large" />} title='About Us' url='../about-us' desc="Read about our mission and vision." />
        <ReportCard icon={<MapIcon fontSize="large" />} title='Contact Us' url='../contact-us' desc="Contact us. Let's have a chat." />
        <ReportCard icon={<AdminPanelSettingsIcon fontSize="large" />} title='Term & Condition' url='../terms-condition' desc="Please accept our terms & service." />
        <ReportCard icon={<PolicyIcon fontSize="large" />} title='Privacy Policy' url='../privacy-policy' desc="Read our privacy policy." />
        <ReportCard icon={<GppGoodIcon fontSize="large" />} title='Refund Policy' url='../refund-policy' desc="Read our refund policy." />
        <ReportCard icon={<StarHalfIcon fontSize="large" />} title='Rate us' url='https://play.google.com/store/apps/details?id=in.round2pay.official' desc="Share your best reviews." />
        <div className='card-profile' onClick={handleLogout}>
          <div className='text-app'>
            <LogoutIcon fontSize="large" />
          </div>
          <div className='card-profile-header'>
            <h4>Logout</h4>
            <p style={{ fontSize: '13px' }}>Logout from this account.</p>
          </div>
        </div>
        <div className='developed_by'>
          <p style={{ fontSize: '12px' }}>Designed & Developed by <b>Webhook Technologies Pvt. Ltd.</b></p>
          <p style={{ fontSize: '11px' }}><a style={{ textDecoration: 'none', color: 'black' }} href="mailto:dev.tamizuahmed@gmail.com">dev.tamizuahmed@gmail.com</a> | <a style={{ textDecoration: 'none', color: 'black' }} href="tel:+918403015605">+918403015605</a></p>
        </div>
      </div>
    </div>
  )
}

export default Setting