import React, { useState, useEffect } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import Swal from 'sweetalert2';
import '../style/com-share.css';


const ShareLayout = () => {
    const [refer_code, setReferCode] = useState('');

    useEffect(() => {
        const referCode = localStorage.getItem('refer_code');
        if (referCode) {
            setReferCode(referCode);
        }
    },[]);

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share & earn!',
                    text: 'Download our Recharge app and use the promo code ' + refer_code + ' to get an exclusive upto 5% discount on your recharges!',
                    url: 'https://play.google.com/store/apps/details?id=in.round2pay.official',
                });
            } catch (error) {
                console.log("Refer not done yet.")
            }
        } else {
            if (window.AndroidInterface) {
                window.AndroidInterface.share(
                    'Share & earn!',
                    'Download our Recharge app and use the promo code ' + refer_code + ' to get an exclusive upto 5% discount on your recharges!',
                    'https://play.google.com/store/apps/details?id=in.round2pay.official'
                );
            } else {
                Swal.fire('Ohh no', 'Browser not support share features.', 'error');
            }
        }
    };


    return (
        <div style={{margin:'0px 5px 0px'}}>
            <div className='card-profile' style={{backgroundColor:'var(--app-color)',color:'white'}}>
                <div className='card-profile-header'>
                    <p style={{ fontSize: '16px' }}><b>Refer & Earn</b></p>
                    <p style={{ fontSize: '12px', marginTop:'2px', marginBottom:'2px' }}>Share Round2Pay with your friens and other, earn uptop 20, When they add 500 and more.</p>
                    <p style={{ fontSize: '16px' }}>Refer code : <b style={{fontFamily:'monospace'}}>{refer_code}</b></p>
                </div>
                <div className='share-btn' >
                    <ShareIcon onClick={handleShare} />
                </div>
            </div>
        </div>
    )
}

export default ShareLayout