import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const API_URL = process.env.REACT_APP_API_URL;

function Registered(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { number } = location.state || {};

    const [formValue, setFormValue] = useState({
        number: number || '',
        name: '',
        email: '',
        agreed: false
    });

    const [registerbtn, setRegisterBtn] = useState(false);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormValue({
            ...formValue,
            [name]: newValue,
        });
    };

    const HandleRegistered = (event) => {
        event.preventDefault();
        if (
            formValue.number.length === 10 &&
            formValue.agreed &&
            formValue.name.trim() !== "" &&
            formValue.email.trim() !== ""
        ) {
            sentOtp(formValue.number);
        } else {
            toast.error('Please enter a valid name, email, number and agree to the terms.');
        }
    };

    async function sentOtp(number) {
        setRegisterBtn(true);
        try {
            const response = await fetch(
                `${API_URL}register.php?number=${number}&name=${formValue.name}&email=${formValue.email}&token=1222`
            );
            const data = await response.json();

            if (data.status === 'Failed') {
                if (data.action === 'login') {
                    Swal.fire({
                        title: 'Login?',
                        text: data.message,
                        icon: 'question',
                        confirmButtonText: 'Login',
                        cancelButtonText: 'Cancel',
                        showCancelButton: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('../login');
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire('Cancelled', 'Your registration was not completed', 'info');
                        }
                    });
                } else {
                    Swal.fire('Error', data.message, 'error');
                }
            } else {
                navigate('../otp-verification', { state: { number: number } });
            }
        } catch (error) {
            Swal.fire('Error', 'Something went wrong. Please try again.', 'error');
        } finally {
            setRegisterBtn(false);
        }
    }

    const checkPolicyPages = (event) => {
        navigate(event.target.getAttribute('url'));
    };

    return (
        <div className='login-body'>
            <div className="login-header-item">
                <h1 className="login-title">Welcome!</h1>
                <h2 className="login-subtitle">Sign up to continue</h2>
            </div>
            <div className="login-container">
                <form className="login-form" onSubmit={HandleRegistered}>
                    <label htmlFor="name" className="login-label">Name</label>
                    <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={formValue.name}
                        placeholder='Enter your name'
                        className='form-control'
                        autoComplete='off'
                        required
                        minlength="5"
                        maxlength="64"
                        pattern=".{5,64}"
                        title="Name must be between 5 and 64 characters long."
                    />
                    <label htmlFor="email" className="login-label">Email</label>
                    <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={formValue.email}
                        placeholder='Enter your email'
                        className='form-control'
                        autoComplete='off'
                        required
                        minlength="8"
                        maxlength="50"
                        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                        title="Please enter a valid email address (e.g., user@example.com)."
                    />
                    <label htmlFor="number" className="login-label">Mobile Number</label>
                    <input
                        type="text"
                        name="number"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value) && value.length <= 10) {
                                handleChange(e);
                            }
                        }}
                        value={formValue.number}
                        placeholder='Enter your mobile number'
                        className='form-control'
                        autoComplete='off'
                        required
                        pattern="[6-9][0-9]{9}"
                        title="Mobile number must be a valid number."
                    />
                    {/* Terms and Conditions */}
                    <div className="login-checkbox">
                        <input
                            type="checkbox"
                            name="agreed"
                            checked={formValue.agreed}
                            onChange={handleChange}
                            id="agreed"
                        />
                        <label htmlFor="agreed" className="login-checkbox-text">
                            I agree to the <span style={{color:'green'}} onClick={checkPolicyPages} url="/terms-condition">Terms & Conditions</span>
                        </label>
                    </div>
                    <button
                        type="submit"
                        className="login-signin-btn"
                        disabled={registerbtn}
                    >
                        {registerbtn ? 'Registering...' : 'REGISTER'}
                    </button>
                </form>
                <div className="login-signup-text">
                    Already have an account?{' '}
                    <span
                        onClick={() => navigate(-1)}
                        className="login-signup-link"
                        style={{ cursor: 'pointer' }}
                    >
                        Login
                    </span>
                </div>
            </div>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                toastOptions={{
                    duration: 3000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: {
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
        </div>
    );
}

export default Registered;
