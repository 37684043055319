import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderActivity from '../components/HeaderActivity';
import Swal from 'sweetalert2';
import '../style/biller-file.css';
import ErrorPage from '../components/ErrorPage';
import TransactionProcessing from '../components/TransactionProcessing';
import TransactionSucess from '../components/TransactionSucess';
import TransactionFailed from '../components/TransactionFailed';
import TransactionPending from '../components/TransactionPending';

const API_URL = process.env.REACT_APP_API_URL;

const BbpsForm = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState({});
    const [operatorId, setOperatorId] = useState('');
    const [billDetails, setBillDetails] = useState(null);
    const [billfetchId, setBillFetchId] = useState('');
    const [finalAmount, setFinalAmount] = useState(0);

    const [loading, setLoading] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [inputFields, setInputFields] = useState([]);
    const [combinedString, setCombinedString] = useState();

    // Loading States for Buttons
    const [isBillFetchLoading, setIsBillFetchLoading] = useState(false);

    const [transactionProcessing, setTransactionProcessing] = useState(false);
    const [transactionPending, setTransactionPending] = useState(false);
    const [transactionSuccess, setTransactionSuccess] = useState(false);
    const [transactionFailed, setTransactionFailed] = useState(false);


    ///transaction info
    const [order_id, setOrderId] = useState('');
    const [txn_id, setTxnId] = useState('');
    const [txn_msg, setTxnMsg] = useState('');

    // Memoize the fetchInputs function to prevent it from being recreated on each render
    const fetchInputs = useCallback(async (operatorId) => {
        setLoading(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}operator_input_field.php?key=${key}&operator_id=${operatorId}`);
            if (!response.ok) throw new Error('Failed to fetch form inputs');
            const data = await response.json();

            if (data.status === 'Success') {
                processInputFields(data.data1);
            } else {
                setErrorPage(true);
                setErrorMsg(data.message);
            }
        } catch (error) {
            setErrorPage(true);
            setErrorMsg(error.message);
        } finally {
            setLoading(false);
        }
    }, []);

    // UseEffect hook to handle the operatorId and fetch input fields
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const operatorId = params.get('operator_id');

        if (operatorId) {
            setOperatorId(operatorId);
            fetchInputs(operatorId);
        } else {
            Swal.fire({
                title: 'Failed!',
                text: 'Technical issue occurred.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(() => {
                navigate(-1);
            });
        }
    }, [location, navigate, fetchInputs]); // fetchInputs is now a stable function

    const processInputFields = (data) => {
        const fields = [];
        for (let i = 1; i <= parseInt(data.nparam, 10); i++) {
            if (data[`paramName_${i}`]) {
                fields.push({
                    name: `param${i}`,
                    hint: data[`paramHint_${i}`],
                    note: data[`paramNote_${i}`],
                    type: data[`paramType_${i}`] === '1' ? 'number' : 'text',
                    isOptional: data[`paramIsOptional_${i}`] === '1',
                    minLength: parseInt(data[`paramMinL_${i}`], 10),
                    maxLength: parseInt(data[`paramMaxL_${i}`], 10),
                    regex: data[`paramRegEx_${i}`],
                });
            }
        }

        setInputFields(fields);

        const initialFormData = {};
        fields.forEach(field => {
            initialFormData[field.name] = '';
        });
        setFormData(initialFormData);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let combinedString = '';
        inputFields.forEach(field => {
            const paramName = field.name;
            const paramValue = formData[paramName];
            combinedString += `&${paramName}=${paramValue}`;
        });

        setCombinedString(combinedString);
        fetchBill(combinedString);
    };

    const fetchBill = async (combinedString) => {
        setIsBillFetchLoading(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}operator_bill_fetch.php?key=${key}&operator_id=${operatorId}${combinedString}`);
            const data = await response.json();

            if (data.status === 'Success') {
                setBillDetails(data.data1);
                setFinalAmount(data.data1.billnetamount);
                setBillFetchId(data.data4);
            } else {
                Swal.fire('Failed!', data.message, 'error');
            }
        } catch (error) {
            Swal.fire('Failed!', error.message || 'Technical issue occurred.', 'error');
        } finally {
            setIsBillFetchLoading(false);
        }
    };

    const handlePayment = () => {
        if (finalAmount && !isNaN(parseFloat(finalAmount)) && parseFloat(finalAmount) > 0 && operatorId && billDetails?.cellNumber) {
            Swal.fire({
                title: 'Confirm?',
                text: 'Do you want to proceed with your bill payment?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Confirmed'
            }).then((result) => {
                if (result.isConfirmed) {
                    billPayment();
                }
            });
        } else {
            Swal.fire('Error', 'Enter valid details', 'error');
        }
    };

    const billPayment = async () => {
        setTransactionProcessing(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}universal_payment.php?key=${key}&operator_id=${operatorId}&bill_fetch_id=${billfetchId}&amount=${finalAmount}${combinedString}`);
            const data = await response.json();

            if (data.status === 'Success') {
                setOrderId(data.data2);
                setTxnId(data.data1);
                setTransactionSuccess(true);
            } else if (data.status === 'Failed') {
                setOrderId(data.data2);
                setTxnId(data.data1);
                setTxnMsg(data.message);
                setTransactionFailed(true);
            } else {
                setOrderId(data.data2);
                setTxnId(data.data1);
                setTransactionPending(true);
            }
        } catch (error) {
            setOrderId('');
            setTxnId('');
            setTransactionPending(true);
        } finally {
            setTransactionProcessing(false);
        }
    };

    const finalAmountHandle = (event) => {
        setFinalAmount(event.target.value);
    }

    return (
        <div>
            {loading ? (
                <div className="loading_center">
                    <div className="loading"></div>
                </div>
            ) : errorPage ? (
                <ErrorPage message={errorMsg} callback={() => fetchInputs(operatorId)} />
            ) : transactionProcessing ? (
                <TransactionProcessing />
            ) : transactionPending ? (
                <TransactionPending transactionId={txn_id} orderId={order_id} txnMsg="" />
            ) : transactionSuccess ? (
                <TransactionSucess transactionId={txn_id} orderId={order_id} txnMsg="" />
            ) : transactionFailed ? (
                <TransactionFailed transactionId={txn_id} orderId={order_id} txnMsg={txn_msg} />
            ) : (
                <div>
                    <HeaderActivity title='Operator Form' />
                    <div className='page-container'>
                        <form onSubmit={handleSubmit} className='form_div'>
                            {inputFields.map((field, index) => (
                                <div key={index}>
                                    <input
                                        type="text"
                                        name={field.name}
                                        placeholder={field.hint}
                                        value={formData[field.name]}
                                        required={!field.isOptional}
                                        {...(field.regex ? { pattern: field.regex } : { minLength: field.minLength, maxLength: field.maxLength })} // Apply min/max only when regex is absent
                                        onChange={handleInputChange}
                                        className='form-control'
                                    />
                                </div>
                            ))}
                            <button type="submit" className='form-btn'>
                                {isBillFetchLoading ? (
                                    <div className="loading-btn"></div>
                                ) : (
                                    <div className='btn-text'>Bill Fetch</div>
                                )}
                            </button>
                        </form>

                        {billDetails && (
                            <div className='form_div'>
                                <div>
                                    <h4 style={{ textDecoration: 'underline', textAlign: 'center', marginBottom: '5px', marginTop: '5px' }}>Bill Details</h4>
                                    <p><strong>Customer:</strong> {billDetails.userName}</p>
                                    <p><strong>Cosumer Number:</strong> {billDetails.cellNumber}</p>
                                    <p><strong>Available Bal:</strong> {billDetails.balance}</p>
                                    <p><strong>Last Recharge:</strong> {billDetails.nextRecharge}</p>
                                    <p><strong>Plan Details:</strong> {billDetails.planName}</p>
                                    <p><strong>Status:</strong> {billDetails.status}</p>
                                    <p><strong>Bill Date:</strong> {billDetails.billdate}</p>
                                    <p><strong>Due Date:</strong> {billDetails.dueDate}</p>
                                    <p><strong>Bill Amount:</strong> {billDetails.billAmount}</p>
                                    <p><strong>Convenience Fee:</strong> {billDetails.bill_convenience_fee}</p>
                                    <p><strong>Net Amount:</strong> {billDetails.billnetamount}</p>

                                    <input
                                        type="number"
                                        className='form-control'
                                        autoComplete='off'
                                        onChange={finalAmountHandle}
                                        value={finalAmount}
                                        readOnly={!billDetails.acceptPartPay}
                                    />
                                </div>
                                <button className='form-btn cursor-pointer' disabled={transactionProcessing} onClick={handlePayment}>
                                    {transactionProcessing ? (
                                        <div className="loading-btn"></div>
                                    ) : (
                                        <div className='btn-text'>Pay Now</div>
                                    )}
                                </button>
                            </div>

                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BbpsForm;
