import React from 'react'
import HelpIcon from '@mui/icons-material/SupportAgent';
import BannerLogo from '../assets/round2pay_banner.png'

const Header = () => {
  return (
    <div className='header'>
      <img src={BannerLogo} loading="lazy"
        className="lazy-load-image" alt='' style={{ height: '40px', width: '80px' }} />
      <a href='https://wa.me/917578931458' target='_BLANK' rel='noreferrer'>
        <HelpIcon style={{ color: '#ffffff', paddingRight: '10px' }} />
      </a>
    </div>
  )
}

export default Header