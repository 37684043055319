import React, { useEffect, useState } from 'react'
import HeaderActivity from '../components/HeaderActivity'
import Balance from '../components/Balance'
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import isWebView from '../utilities/detectWebView';


const API_URL = process.env.REACT_APP_API_URL;



const TopUp = () => {
    const [addmoneybtn, setAddmoneyBtn] = useState(false);
    const [entered_amount, setAmount] = useState('');
    const navigator = useNavigate();

    const [isWebViewState, setIsWebViewState] = useState(false);
    useEffect(() => {
        setIsWebViewState(isWebView());
    }, []);


    const handleChange = (event) => {
        setAmount(event.target.value);
    }

    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var amount = urlParams.get('amount');
    var upgrade = urlParams.get('upgrade');

    if (amount !== '' && upgrade !== '') {

    }

    async function getPaymentUlr() {
        setAddmoneyBtn(true);
        try {
            const key = localStorage.getItem('key');
            const response = await fetch(`${API_URL}payment.php?key=${key}&amount=${entered_amount}`); // Replace with your API endpoint
            const data = await response.json();
            if (data.status === 'Failed') {
                Swal.fire('Failed', data.message, 'error');
            } else {
                if (data.data3 === 'web' || data.data3 === 'intent') {
                    window.open(data.data1, '_blank', 'noopener,noreferrer');
                } else if (data.data3 === 'qr') {
                    navigator('../check-out', { state: { url: data.data1, order_id: data.data2 } });
                    //window.open(data.data1, '_blank', 'noopener,noreferrer');
                } else if (data.data3 === 'intent_page_ccavenue') {
                    {/* 
                    const url = btoa(data.url);

                    if (isWebViewState) {
                        window.location.href = "intent_page_ccavenue:" + data.url;
                    } else {
                        window.open(data.url, '_blank', 'noopener,noreferrer');
                    }
                    */}
                } else{
                    Swal.fire('Ohh no', 'No route found.', 'error');
                }
            }
        } catch (error) {
            Swal.fire('Ohh no', 'Something went wrong.', 'error');
        } finally {
            setAddmoneyBtn(false);
        }
    }

    const HandleAddmoney = (event) => {
        const pattern = /^[0-9]*$/;
        if (entered_amount !== "" && parseInt(entered_amount) > 0) {
            if (pattern.test(entered_amount)) {
                getPaymentUlr();
            } else {
                toast.error('Enter enter only number.');
            }
        } else {
            toast.error('Enter valid input.');
        }
    }

    const handleRecomend = (event) => {
        setAmount(event.target.innerText);
    }


    return (
        <div>
            <HeaderActivity title='Add money' />
            <div className='page-container'>
                <Balance />
                <div className='form_div'>
                    <div>
                        <input
                            type="number"
                            name="number"
                            placeholder='Enter amount'
                            className={'form-control'}
                            autoComplete='off'
                            value={entered_amount}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='topup-recommended-amount-list'>
                        <div className='topup-recommended-amount' onClick={handleRecomend}>500</div>
                        <div className='topup-recommended-amount' onClick={handleRecomend}>1000</div>
                        <div className='topup-recommended-amount' onClick={handleRecomend}>3000</div>
                        <div className='topup-recommended-amount' onClick={handleRecomend}>5000</div>
                        <div className='topup-recommended-amount' onClick={handleRecomend}>10000</div>
                    </div>
                    <button className='form-btn cursor-pointer' disabled={addmoneybtn} onClick={HandleAddmoney}>
                        {addmoneybtn ? (
                            <div className="loading-btn"></div>
                        ) : (
                            <div className='btn-text'>Pay Now</div>
                        )}
                    </button>
                </div>
            </div>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 3000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
        </div>
    )
}

export default TopUp