import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import '../style/com-balance.css';

const API_URL = process.env.REACT_APP_API_URL;

const Balance = () => {
  const [balances, setBalances] = useState([]);

  useEffect(() => {
    const key = localStorage.getItem('key');
    
    const fetchBalance = async () => {
      try {
        const response = await fetch(`${API_URL}day-book-summary.php?key=${key}`);
        const data = await response.json();
        
        if (data.status === 'Success') {
          // Set the response data to balances
          setBalances(data.data1);
        } else {
          Swal.fire('Ohh no', data.message, 'error');
        }
      } catch (err) {
        Swal.fire('Ohh no', 'Something went wrong.', 'error');
      }
    };

    fetchBalance();
  }, []);

  return (
    <div>
      <div className='transaction-summary-container'>
        <div className='transaction-summary'>
          {/* Iterate over the balances to display each transaction */}
          {balances.map((balance, index) => (
            <div
              key={index}
              className='transaction-card'
              style={{ backgroundColor: balance.color_code }}
            >
              <p>{balance.category}</p>
              <p>{balance.amount}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Balance;
