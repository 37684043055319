import React, { useState, useEffect } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const ErrorPage = (props) => {
    const navigate = useNavigate();
    const useOnlineStatus = () => {
        const [isOnline, setIsOnline] = useState(navigator.onLine);
        useEffect(() => {
            const handleOnline = () => setIsOnline(true);
            const handleOffline = () => setIsOnline(false);
    
            window.addEventListener('online', handleOnline);
            window.addEventListener('offline', handleOffline);
    
            return () => {
                window.removeEventListener('online', handleOnline);
                window.removeEventListener('offline', handleOffline);
            };
        }, []);
    
        return isOnline;
    };


    const isOnline = useOnlineStatus();

    const pageReload = () => {
        const callback = props.callback;
        if (typeof callback === 'function') {
            console.log('callback is a function');
            callback();
        } else {
            console.error('callback is not a function');
        }
    };

    
    return (
        <div className='error-page'>
            <div className='error-back-button' onClick={() => navigate(-1)}><ArrowBackIcon/> Back</div>
            <div className="error-content">
                <ErrorOutlineOutlinedIcon style={{height:'60px', width:'60px', marginBottom:'15px', color:'red'}}/>
                <p>{isOnline ? "Failed!" : "No internet connection!"}</p>
                <p style={{marginTop:'5px'}}>{props.message}</p>
                <button onClick={pageReload} className='error-refresh-btn'><RefreshIcon /> Refresh</button>
            </div>
        </div>
    );
};

export default ErrorPage;
